define([
	'app/components/Popups/PopupParameters',
	'app/components/Popups/Popups',
], (PopupParameters, Popups) => {

	function MultipleHellFormControls(controls, name, allowEmpty) {
		this.root = $(controls);
		this.selector = 'a[data-popup][data-multi="' + name + '"]';
		this.checkedCount = 0;
		this.allowEmpty = allowEmpty;
		this.events();
		this.hideIfNotUseful();
	}
	MultipleHellFormControls.prototype = {

		events: function () {

			this.root.on('click', '[data-controls-action="enter"]', () => {
				this.enter();
				return false;
			});
			this.root.on('click', '[data-controls-action="toggle"]', () => {
				this.toggle();
				return false;
			});
			this.root.on('click', '[data-controls-action="dismiss"]', () => {
				this.leave();
				return false;
			});

			this.root.find('[data-controls-action="open"][data-popup][data-popup-source]').on('click', _.bind(function (e) {
				e.preventDefault();
				e.stopPropagation();
				this.open($(e.currentTarget));
			}, this));

			$(document).on('change', this.selector + ' + span > label > input:checkbox', _.bind(function (e) {
				if (!MultipleHellFormControls.isActive(this)) return;
				this.onChangeInput($(e.currentTarget));
				this.onChange();
			}, this));

			this.root.closest('.tableStandard').on('click', 'a.add', _.bind(function () {
				this.leave();
			}, this));
		},

		hideIfNotUseful: function () {
			var interval;
			var check = _.bind(function (isLast) {
				var found = !!$(this.selector).length;
				this.root.toggleClass('hidden', !found);
				if (found || isLast) clearInterval(interval);
			}, this);
			interval = setInterval(check, 100);
			check();
			$(_.partial(check, true));
		},

		each: function (fn) {
			const later = [];
			var queue = [];
			for (const el of $(this.selector))
			{
				const $el = $(el);
				let $input = $el.data('checkbox');
				if (!$input)
				{
					later.push(this.prepareInput($el));
					$input = $el.data('checkbox');
				}
				queue.push([$el, $input]);
			}
			for (const laterFn of later)
			{
				laterFn();
			}
			for (const [$el, $input] of queue)
			{
				fn($el, $input);
				this.onChangeInput($input);
			}
		},

		enter: function () {
			MultipleHellFormControls.activate(this);
			this.root.addClass('enabled');
			this.each(($el, $input) => {
				$el.addClass('enabled');
			});
			$('a[data-popup][data-multi]').addClass('hiddenForMultipleHellFormControls');
			this.onChange();
		},

		leave: function () {
			if (!MultipleHellFormControls.isActive(this)) return;
			this.root.removeClass('enabled');
			$('a[data-popup][data-multi]').removeClass('hiddenForMultipleHellFormControls');
			this.each(($el, $input) => {
				$el.removeClass('enabled');
				$input.prop('checked', false);
			});
			this.onChange();
			MultipleHellFormControls.deactivate();
		},

		toggle: function () {
			this.each(($el, $input) => {
				$input.prop('checked', !$input.prop('checked'));
			});
			this.onChange();
		},

		onChange: function (event) {
			var count = this.checkedCount;
			this.root.find('.numberOfSelectedItems').text(count);
			this.root.find('[data-controls-action="open"]').toggleClass('disabled', (count === 0 && this.allowEmpty === false));
		},

		onChangeInput: function ($input) {
			var lastChecked = $input.data('lastChecked');
			var checked = $input.prop('checked');
			if (lastChecked !== checked)
			{
				$input.data('lastChecked', checked);
				this.checkedCount += checked ? 1 : (lastChecked ? -1 : 0);
				$input.closest('.tableStandard > tbody > tr').toggleClass('m-table__row--selected', !!checked);
			}
		},

		open: function (popup) {
			var parameters = {};
			this.each(($el, $input) => {
				if (!$input.prop('checked')) return;
				var pn = $el.attr('data-popup');
				while (parameters[pn] !== undefined)
				{
					pn = PopupParameters.createFromElement($el, {'__nonce': _.now()}).popupName;
				}
				parameters[pn] = pn;
			});
			parameters = _.toArray(parameters);
			if (parameters.length === 0 && this.allowEmpty === false)
			{
				return;
			}
			PopupParameters.modifyOnElement(popup, {
				parameters: parameters,
			});

			Popups.load(popup.attr('href'), popup.attr('data-popup'));
		},

		prepareInput: function ($el) {
			var checkbox = $('<input>')
				.attr('type', 'checkbox')
			;

			const box = $el[0].getBoundingClientRect(); // does not include margin
			const height = box.height + $el.outerHeight(true) - $el.outerHeight(false);
			const width = box.width + $el.outerWidth(true) - $el.outerWidth(false);

			var label = $('<label>')
				.height(height)
				.width(width)
				.attr('title', $el.text())
			;
			var container = $('<span>')
				.addClass('controlForMultipleHellFormControls')
				.css('vertical-align', $el.css('vertical-align'))
				.height(height)
				.width(width)
			;
			$el.data('container', container).data('checkbox', checkbox.data('popup', $el));
			container.append(label.append(checkbox));
			return () => $el.after(container);
		},

	};

	MultipleHellFormControls.activate = function (instance) {
		if (MultipleHellFormControls.globalActive)
		{
			MultipleHellFormControls.globalActive.leave();
		}
		MultipleHellFormControls.globalActive = instance;
	};

	MultipleHellFormControls.deactivate = function () {
		MultipleHellFormControls.globalActive = null;
	};

	MultipleHellFormControls.isActive = function (instance) {
		return MultipleHellFormControls.globalActive === instance;
	};

	return MultipleHellFormControls;

});
