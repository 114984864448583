define([
	'app/components/FormRepeater/FormRepeater',
	'app/components/Popover/Popover',
	'app/Intranet/Config/components/MessageTemplateForm/Compare',
	'app/Intranet/Config/components/MessageTemplateForm/Languages',
	'app/Intranet/Config/components/MessageTemplateForm/UserPreferences',
], (FormRepeater, Popover, Compare, Languages, UserPreferences) => {

	class MessageForm
	{

		#options;
		#form;
		#customTextRepeater;
		#tabsRepeater;
		#useDefaultRadioSwitch;
		#userPreferences;
		#compare;
		#languages;

		constructor(options)
		{
			this.#options = options;
			this.#form = $(this.#options.form);
			this.#customTextRepeater = new FormRepeater(this.#options.customTextsParameters.rootId, this.#options.customTextsParameters.formName, this.#options.customTextsParameters.containerName);
			this.#tabsRepeater = new FormRepeater(this.#options.customLanguageTabsParameters.rootId, this.#options.customLanguageTabsParameters.formName, this.#options.customLanguageTabsParameters.containerName);
			this.#userPreferences = new UserPreferences(this.#options.userPreferences);
			this.#compare = new Compare({
				...this.#options.compare,
				form: this.#form,
				customTextRepeater: this.#customTextRepeater,
				updateUserPreferences: (lang) => this.#userPreferences.updateCompareLanguage(lang),
			});
			this.#languages = new Languages({
				...this.#options.languages,
				form: this.#form,
				tabsRepeater: this.#tabsRepeater,
				customTextRepeater: this.#customTextRepeater,
				afterAdd: (...args) => {
					this.#compare.addOption(...args);
					this.#refreshPreviewButtons();
					this.#checkUnsaved();
				},
				afterDelete: (lang) => { this.#compare.checkAndDeleteOption(lang); this.#compare.showCompareBox(); },
				afterInaccurate: () => {
					this.#compare.showCompareBox();
					this.#checkUnsaved();
				},
			});

			this.#useDefaultRadioSwitch = this.#form.find('input[name="useDefault"]');
			this.#useDefaultRadioSwitch.on('click', (e) => this.#switchFormMode($(e.target)));
			this.#switchFormMode(this.#useDefaultRadioSwitch.filter(':checked'));
			this.#form.on('input', '.customText', (e) => this.#checkUnsaved($(e.target)));
			this.#form.on('click', '.o-messageTemplateLanguageTab__discardButton', (e) => this.#discardChanges($(e.target)));
			this.#form.on('click', '.o-messageTemplateParametersPopover__insertParamsButton:not(.disabled)', (e) => this.#insertParams($(e.target)));
			this.#form.on('click', '.o-messageTemplateLanguageTab__switchButton', (e) => this.#activeTab($(e.target)));

			this.#checkUnsaved();
		}

		#activeTab(clicked)
		{
			const tab = clicked.closest('.o-messageTemplateLanguageTab');
			this.#form.find('.o-messageTemplateLanguageTab').removeClass('active');
			tab.addClass('active');
			const code = tab.attr('data-language');
			this.#form
				.find(`.tab-content.${$.escapeSelector(tab.attr('data-mode'))}`)
				.find(`.o-messageTemplateLanguageTab[data-language="${$.escapeSelector(code)}"]`)
				.addClass('active')
			;
			this.#compare.showCompareBox();
			this.#userPreferences.updateActiveTabLanguage(code);
		}

		#switchFormMode(checkedRadio)
		{
			if (checkedRadio.val() === '1')
			{
				const changedList = new Set;
				this.#form.find('.o-messageTemplateLanguageTab--changed, .o-messageTemplateLanguageTab--deleted').each((i, el) => {
					changedList.add($(el).attr('data-language').toUpperCase());
				});
				for (const code of this.#languages.getInaccuraciesNotPresentChanged())
				{
					changedList.add(code.toUpperCase());
				}
				if (changedList.size)
				{
					alert(`${this.#options.versionChangeText} (${Array.from(changedList).join(', ')})`);
					return false;
				}
				this.#form.removeClass('modeCustom').addClass('modeDefault');
				this.#initActiveTabAfterSwitchMode($(document.getElementById(this.#options.defaultLanguageTabsParameters.rootId)).find('.o-messageTemplateLanguageTab'));
			}
			else
			{
				this.#form.removeClass('modeDefault').addClass('modeCustom');
				this.#initActiveTabAfterSwitchMode(this.#tabsRepeater.getRows());
			}
			this.#compare.showCompareBox();
			this.#refreshPreviewButtons();
		}

		#initActiveTabAfterSwitchMode(tabs)
		{
			for (const code of [this.#userPreferences.getCurrentActiveTabLanguage(), Sim.config.language, 'en'])
			{
				const tab = tabs.filter(`[data-language="${$.escapeSelector(code)}"]`);
				if (tab.length)
				{
					this.#activeTab(tab);
					return;
				}
			}
			this.#activeTab(tabs.first().filter('[data-language]'));
		}

		#checkUnsaved(changedElement = null)
		{
			const elementsToCheck = this.#customTextRepeater.getRows().find(changedElement ? changedElement : 'textarea.customText');
			elementsToCheck.each((i, el) => {
				const textContainer = $(el).closest('.o-messageTemplateLanguageTab');
				const ctLang = textContainer.attr('data-language');
				const currentTab = this.#tabsRepeater.getRows().filter(`[data-language="${$.escapeSelector(ctLang)}"]`);
				const subject = textContainer.find('input[name$="[subject]"]');
				const content = textContainer.find('textarea[name$="[content]"]');

				const contentOrSubjectChanged = (
					subject.val() !== (subject.attr('data-original-value') ?? '') ||
					content.val() !== (content.attr('data-original-value') ?? '')
				);
				const changed = (
					content.attr('data-original-value') === undefined ||
					contentOrSubjectChanged ||
					this.#languages.areInaccuraciesChanged(ctLang)
				);
				textContainer.toggleClass('o-messageTemplateLanguageTab--changed', !!changed);
				currentTab.toggleClass('o-messageTemplateLanguageTab--changed', !!changed);

				textContainer.find('input[name$="[contentOrSubjectChanged]"]').val(contentOrSubjectChanged ? '1' : ''); // used by form validation
			});
			this.#refreshPreviewButtons();
		}

		#discardChanges(button)
		{
			const lang = button.closest('.o-messageTemplateLanguageTab').attr('data-language');
			if (confirm(button.data('confirmText')))
			{
				this.#languages.deleteLanguage(lang, true);
				const textContainer = this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(lang)}"]`);

				const content = textContainer.find('textarea[name$="[content]"]');
				content.trigger('select');
				if (!document.execCommand('insertText', false, content.attr('data-original-value')))
				{
					content.val(content.attr('data-original-value'));
					content.trigger('input');
				}

				const subject = textContainer.find('input[name$="[subject]"]');
				if (subject.length)
				{
					subject.trigger('select');
					if (!document.execCommand('insertText', false, subject.attr('data-original-value')))
					{
						subject.val(subject.attr('data-original-value'));
						subject.trigger('input');
					}
				}
			}
		}

		#insertParams(selectedParam)
		{
			const textField = selectedParam.closest('.m-controlGroup__controls').find('textarea[name$="[content]"], input[type="text"][name$="[subject]"]');
			const cursorPosition = textField.prop('selectionStart');
			const param = selectedParam.attr('data-insert');
			textField.trigger('focus');
			if (!document.execCommand('insertText', false, param))
			{
				let text = textField.val() ? textField.val() : '';
				text = text.substring(0, cursorPosition) + param + text.substring(cursorPosition);
				textField.val(text).trigger('input');
				textField.prop('selectionEnd', cursorPosition + param.length);
				textField.trigger('focus');
			}
			Popover.init(this.#form.find('button.addParameter.active')).hide();
		}

		#refreshPreviewButtons()
		{
			const useDefaultBool = this.#useDefaultRadioSwitch.filter(':checked').val() === '1';
			for (const el of this.#form.find('.o-messageTemplateLanguageTab--messageText').get())
			{
				const $tab = $(el);
				const changed = $tab.hasClass('o-messageTemplateLanguageTab--changed');
				const disabled = changed || useDefaultBool !== this.#options.useDefault;
				$tab.find('.o-messageTemplateLanguageTab__templatePreviewButton, .o-messageTemplateLanguageTab__templateSendButton')
					.toggleClass('disabled', disabled)
					.attr('title', disabled ? this.#options.unsavedButtonDisabledText : null)
				;
			}
		}

	}

	return MessageForm;
});
