(() => {

	const Sim = window.Sim || (window.Sim = {});

	const disableSoft = (submits) => {
		submits
			.addClass('disabled')
			.attr('data-disabled', (i, value) => (parseInt(value, 10) || 0) + 1)
		;
	};

	const disableHard = (submits) => {
		submits.prop('disabled', true);
	};

	const disable = (submits) => {
		disableHard(submits);
		disableSoft(submits);
	};

	const enable = (submits) => {
		submits
			.attr('data-disabled', (i, value) => (parseInt(value, 10) || 0) - 1)
			.filter('[data-disabled=0]')
			.prop('disabled', false)
			.removeClass('disabled')
			.removeAttr('data-disabled')
		;
	};

	const getSubmitButtons = (el) => {
		const form = $(el).closest('form');
		if (form.length !== 1)
		{
			throw new Error;
		}
		return form
			.not('[data-ignore-for-disable-submit]')
			.find('input[type="submit"], button[type="submit"]')
			.not('[disabled]:not([data-disabled]), .disabled:not([data-disabled]), [data-ignore-for-disable-submit]')
		;
	};

	const createRestore = () => {
		const restores = [];
		const addInstance = (ms, repeat, fn) => {
			let id = (repeat ? setInterval : setTimeout)(() => {
				fn();
				if (!repeat) id = null;
			}, ms);
			restores.push(() => {
				if (id) (repeat ? clearInterval : clearTimeout)(id);
				id = null;
			});
		};
		const handle = {
			add: (fn) => { restores.push(fn); },
			delay: (ms, fn) => addInstance(ms, true, fn),
			repeat: (ms, fn) => addInstance(ms, false, fn),
			delayOrAdd: (ms = null, fn) => (ms === null ? handle.add(fn) : handle.delay(ms, fn)),
		};
		return [handle, () => { for (let fn; (fn = restores.shift());) fn(); }];
	};

	Sim.disableSubmit = (event, options = {}) => {
		if (!(event instanceof $.Event)) throw new Error;
		if (event.type !== 'submit') throw new Error(event.type);
		if (!$(event.target).is('form')) throw new Error;

		const [handle, restoreSubmit] = createRestore();

		restoreSubmit.useNativeSubmitEvent = () => {
			handle.repeat(250, () => {
				if (!Sim.unloadManager.is()) // user stopped the navigation, or 15s timeout
				{
					restoreSubmit();
				}
			});
			handle.delay(0, () => { // later so any even listener can cancel
				if (event.isDefaultPrevented()) // revoke for not ran submits
				{
					restoreSubmit();
				}
			});
		};
		setTimeout(() => (restoreSubmit.useNativeSubmitEvent = () => { throw new Error; }), 0);

		const self = Sim.disableSubmit.create(event.target, options);
		self.startDelayed(); // later so button value is present in data
		handle.add(() => self.stop());

		return restoreSubmit;
	};

	Sim.disableSubmit.create = (el, options = {}) => {
		options = {
			reEnableTimeout: 0,
			...options,
		};
		let submits;
		const [handle, restore] = createRestore();
		const self = {

			startDelayed: () => {
				if (!submits)
				{
					submits = getSubmitButtons(el);
					disableSoft(submits);
					submits.on('click.disableSubmit', false);
					handle.add(() => submits.off('click.disableSubmit'));
					handle.delay(0, () => self.start());
				}
			},

			start: () => {
				restore();

				if (submits)
				{
					disableHard(submits);
				}
				else
				{
					submits = getSubmitButtons(el);
					disable(submits);
				}
				handle.add(() => {
					enable(submits);
					submits = null;
				});

				if (options.reEnableTimeout)
				{
					handle.delay(options.reEnableTimeout * 1000, () => {
						const $document = $(document);
						$document.one('mousemove', restore);
						handle.add(() => $document.off('mousemove', restore));
					});
				}
			},

			stop: () => restore(),

			isDisabledElsewhere: () => {
				let selects = getSubmitButtons(el).filter('[data-disabled]');
				if (submits)
				{
					selects = selects.not('[data-disabled=1]');
				}
				return selects.length !== 0;
			},
		};
		return self;
	};

})();
