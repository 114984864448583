(() => {

	const Sim = window.Sim || (window.Sim = {});

	class EnableAjaxSubmitHandler
	{

		#enablesEarly = [];
		#enables = [];
		#successFns = [];
		#failFns = [];

		constructor()
		{
			this.enableMustIf(() => this.#successFns.length || this.#failFns.length);
			this.addIsEnabled = (fn) => this.enableMustIf(fn); // deprecated
			this.success = this.#successFns; // deprecated
			this.fail = this.#failFns; // deprecated
		}

		#isDisabled(isEarly, $form, submittedBy = $form[0]['nette-submittedBy'])
		{
			for (const fn of this.#enablesEarly)
			{
				if (!fn($form, submittedBy)) return true;
			}
			if (!isEarly)
			{
				for (const fn of this.#enables)
				{
					if (!fn($form, submittedBy)) return true;
				}
			}
			return false;
		}

		enableMustIf(fn)
		{
			this.#enables.push(fn);
			return this;
		}

		enableEarlyMustIf(fn)
		{
			this.#enablesEarly.push(fn);
			return this;
		}

		disableEarlyIfNotUnique()
		{
			return this.enableEarlyMustIf(($form) => {
				for (const as of $form.data('enableAjaxSubmit'))
				{
					if (as === this) continue;
					if (as.recursiveEnableIfUnique) throw new Error;
					try
					{
						this.recursiveEnableIfUnique = true;
						if (!as.#isDisabled(false, $form)) return false;
					}
					finally
					{
						delete this.recursiveEnableIfUnique;
					}
				}
				return true;
			});
		}

		onSuccess(success)
		{
			this.#successFns.push(success);
			return this;
		}

		onFail(fn)
		{
			this.#failFns.push(fn);
			return this;
		}

		#trigger($form, event, onSubmit, disableSubmitOptions)
		{
			const submittedBy = $form[0]['nette-submittedBy'];
			if (event.isDefaultPrevented() || $form[0]['rentflow-isForceSubmitted'] || this.#isDisabled(true, $form, submittedBy))
			{
				return;
			}

			const restoreSubmit = Sim.disableSubmit(event, disableSubmitOptions);

			if (this.#isDisabled(false, $form, submittedBy))
			{
				restoreSubmit.useNativeSubmitEvent();
				return;
			}

			return onSubmit($form, submittedBy, restoreSubmit);
		}

		static initialize(createOnSubmit, forms, disableSubmitOptions)
		{
			const self = new EnableAjaxSubmitHandler;
			Sim.require.callback('Nette', (Nette) => {
				const onSubmit = createOnSubmit(self);
				$(forms).filter('form').each((i, form) => {
					Nette.initForm(form);
					const $form = $(form);
					$form.on('submit', (event) => self.#trigger($form, event, onSubmit, disableSubmitOptions));
					$form.data('enableAjaxSubmit', ($form.data('enableAjaxSubmit') || []).concat([self]));
					if (Sim.config.debuggerEnabled)
					{
						$form.on('click', 'input[type="submit"], button[type="submit"], input[type="image"]', (event) => {
							if (
								(event.ctrlKey && event.shiftKey) ||
								(event.originalEvent?.getModifierState?.('AltGraph'))
							)
							{
								$form[0]['rentflow-isForceSubmitted'] = true;
								setTimeout(() => { delete $form[0]['rentflow-isForceSubmitted']; }, 0);
							}
						});
					}
				});
			});
			return self;
		}

	}

	Sim._enableAjaxSubmit = EnableAjaxSubmitHandler;
})();
