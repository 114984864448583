define(['Helpers/ElementFinder'], (ElementFinder) => {

	class BankTransactionMarkDuplicate
	{

		#options;
		#getAmountAndCurrency;
		#root;
		#inputs;
		#dateSelector = 'input[name="actual[date]"]';
		#receiverSelector = 'select[name="actual[receiver]"], select[name="actual[directions][transfer][receiver]"], select[name="actual[directions][deposit][receiver]"]';
		#selectButton;

		constructor(options, getAmountAndCurrency, rootFinder)
		{
			this.#options = options;
			this.#getAmountAndCurrency = getAmountAndCurrency;
			this.#root = rootFinder;

			this.#inputs = {};
			for (const $el of this.#root.iterateSome('input[type="hidden"][name^="search["]'))
			{
				this.#inputs[$el.attr('name').replace(/^search\[(.+)\]$/u, '$1')] = $el;
			}

			this.#root.on(this.#dateSelector, 'change input', () => this.check());
			this.#root.on(this.#receiverSelector, 'change', () => this.check());
			this.#root.on('select[name="actual[subtype]"]', 'change', () => this.check());

			this.#selectButton = this.#root.findOne('input[type="submit"][data-ref="AjaxSubmitFillingFormFactory"]');
			this.#root.on('input[type="radio"][name="linkWithActualPayment"]', 'change', (e) => {
				if ($(e.target).val() !== (this.#getWarning().attr('data-value') || 'no-auto'))
				{
					this.#selectButton.trigger('click');
				}
			});
		}

		check(amountAndCurrency = null)
		{
			const $warning = this.#getWarning();
			if ($warning.length !== 0 && !$warning.hasClass('o-bankTransactionMark__duplicateWarning--uninitialized'))
			{
				const warningFinder = this.#root.derive($warning);
				warningFinder.findOne('input[type="radio"][value="no-manual"]', false).prop('checked', false);
				warningFinder.findOne('input[type="radio"][value="no-auto"]').prop('checked', false);
			}
			if (amountAndCurrency)
			{
				this.#lookupDebounce.cancel();
				setTimeout(() => this.#lookupNow(amountAndCurrency), 0);
			}
			else
			{
				this.#lookupDebounce(amountAndCurrency);
			}
		}

		#lastRequest = {
			queryData: undefined,
			response: undefined,
			abort: undefined,
			ajax: undefined,
			ajaxDelay: undefined,
		};

		#lookupDebounce = _.debounce((...a) => this.#lookupNow(...a), 100);

		#lookupNow(amountAndCurrency = null)
		{
			const {amount, currencyCode} = amountAndCurrency || this.#getAmountAndCurrency();
			const data = {
				..._.mapObject(this.#inputs, ($el) => $el.val()),
				amount,
				currencyCode,
				date: amount === null ? null : Sim.parseDatePickerValue(this.#root.findOne(this.#dateSelector)),
				receiver: null,
			};
			if (amount !== null)
			{
				const $receiver = this.#root.findAny(this.#receiverSelector).filter(':visible');
				if ($receiver.length > 1) throw new Error(_.map($receiver, (el) => $(el).attr('name')).join(', '));
				data.receiver = $receiver.val() || null;
			}
			const queryData = JSON.stringify(data);
			if (this.#lastRequest.queryData === queryData && this.#lastRequest.response !== undefined && this.#lastRequest.abort === undefined)
			{
				this.#render(this.#lastRequest.response, queryData);
				return;
			}
			if (this.#lastRequest.abort !== undefined)
			{
				this.#lastRequest.abort();
			}
			const $warning = this.#getWarning();
			if ($warning.length === 0)
			{
				$warning.removeClass('o-bankTransactionMark__duplicateWarning--looking');
				return;
			}
			if ($warning.hasClass('o-bankTransactionMark__duplicateWarning--uninitialized'))
			{
				if ($warning.attr('data-query-data') === queryData)
				{
					$warning.removeClass('o-bankTransactionMark__duplicateWarning--looking');
					$warning.removeClass('o-bankTransactionMark__duplicateWarning--uninitialized');
					return;
				}
				Sim.triggerWarning(`BankTransactionMarkDuplicate: queryData are not equal '${$warning.attr('data-query-data')}' '${queryData}'`);
			}
			$warning.addClass('o-bankTransactionMark__duplicateWarning--looking');
			this.#lastRequest.queryData = queryData;
			this.#lastRequest.response = undefined;
			this.#ajaxDelay(500, {
				url: this.#options.ajax.url,
				method: 'POST',
				data: {[this.#options.ajax.parameter]: data},
				dataType: 'json',
				global: false, // ignore Sim.loading
				success: (json) => {
					this.#render(this.#lastRequest.response = json, queryData);
				},
				error: () => {
					this.#lastRequest.queryData = undefined;
					this.#lastRequest.response = undefined;
				},
			});
		}

		#ajaxDelay(delay, settings)
		{
			if (this.#lastRequest.abort !== undefined)
			{
				this.#lastRequest.abort();
			}
			const clear = () => {
				if (this.#lastRequest.ajaxDelay)
				{
					clearTimeout(this.#lastRequest.ajaxDelay);
					this.#lastRequest.ajaxDelay = undefined;
				}
			};
			const trigger = () => {
				this.#lastRequest.ajax = $.ajax({
					...settings,
					complete: () => {
						this.#lastRequest.ajax = undefined;
						this.#lastRequest.abort = undefined;
					},
				});
			};
			this.#lastRequest.ajaxDelay = setTimeout(trigger, delay);
			this.#lastRequest.abort = () => {
				clear();
				this.#lastRequest.ajax && this.#lastRequest.ajax.abort();
				this.#lastRequest.abort = undefined;
			};
		}

		#createRadio(submittedValue, $template, value, labelHtml)
		{
			const id = `o-bankTransactionMark__duplicateWarningRadio-id-${value}`;
			const $el = $template.clone().attr('data-radio', value);
			const elFinder = new ElementFinder($el);
			elFinder.findOne('input[type="radio"]')
				.attr('value', value)
				.prop('checked', value === submittedValue)
				.attr('id', id)
			;
			elFinder.findOne('label')
				.html(labelHtml)
				.attr('for', id)
			;
			$el.insertBefore($template);
		}

		#render(results, queryData)
		{
			const $warning = this.#getWarning();
			if ($warning.length === 0)
			{
				return;
			}
			const warningFinder = this.#root.derive($warning);
			const $radioNoAuto = warningFinder.findAny('.o-bankTransactionMark__duplicateWarningRadio[data-radio="no-auto"]');
			warningFinder.findAny('.o-bankTransactionMark__duplicateWarningRadio').not($radioNoAuto).remove();
			if (results.length)
			{
				const submittedValue = String($warning.attr('data-value'));
				for (const res of results)
				{
					const labelHtml = Sim.escapeHtml($warning.attr('data-yes-label').replace('%user%', res.addedBy))
						.replace('%date%', $('<span class="a-hoverableText">').attr('title', res.insertedTime).text(res.insertedDate)[0].outerHTML)
					;
					this.#createRadio(submittedValue, $radioNoAuto, String(res.actualPaymentId), labelHtml);
				}
				this.#createRadio(
					$warning.hasClass('o-bankTransactionMark__duplicateWarning--uninitialized') ? submittedValue : null,
					$radioNoAuto,
					'no-manual',
					$radioNoAuto.find('label').html()
				);
			}
			this.#root.derive($radioNoAuto).findOne('input[type="radio"][value="no-auto"]').prop('checked', !results.length);
			$warning.toggleClass('o-bankTransactionMark__duplicateWarning--found', !!results.length);
			$warning.removeClass('o-bankTransactionMark__duplicateWarning--looking');
			$warning.removeClass('o-bankTransactionMark__duplicateWarning--uninitialized');
			$warning.attr('data-query-data', queryData);
		}

		#getWarning()
		{
			return this.#root.findOne('.o-bankTransactionMark__duplicateWarning', false);
		}

	}

	return BankTransactionMarkDuplicate;
});
