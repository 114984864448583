define(['./DropdownMenuPick'], (DropdownMenuPick) => {

	class DropdownMenuSearch extends DropdownMenuPick
	{

		#options;
		#searchInput;

		#menuItem = $('<li class="m-dropdownMenu__row"><a></a></li>');
		#menuItemText = $(`
			<span class="a-breakText a-breakText--no">
				<span class="js-text"></span>
				<span class="a-interpunct"></span>
			</span>
		`);

		#lastValue = null;
		#statusInitial = true;

		constructor(options)
		{
			super({
				container: options.container,
				triggerElementSelector: options.searchInputSelector,
				menu: null,
				open: () => {
					this.#lookup.cancel();
					this.#lookupNow();
				},
			});
			this.#options = {
				minLength: 3,
				numberOfItems: -1,
				...options,
			};

			this.#searchInput = this.$triggerElement();
			this.#lastRequest.queryString = this.#searchInput.val();

			this.#searchInput.on({
				'input keyup': (e) => {
					if (!e.isDefaultPrevented())
					{
						this.#statusInitial = false;
						this.#lookup();
					}
				},
			});

			$(Object.values(this.#options.ajax.parameters || {})).on('change', (e) => {
				if (this.#lastValue !== null)
				{
					this.selectItem(null, null, e);
				}
			});
		}

		#lastRequest = {
			queryString: '',
			queryData: undefined,
			response: undefined,
			ajax: undefined,
		};

		#lookup = _.debounce(() => this.#lookupNow(), 100);

		#lookupNow()
		{
			const query = this.#searchInput.val() || '';
			const data = {
				..._.mapObject(this.#options.ajax.parameters || {}, (el) => $(el).val()),
				[this.#options.ajax.queryParameter]: query,
			};
			if (this.#lastRequest.queryData === JSON.stringify(data))
			{
				if (
					this.#lastRequest.response !== undefined &&
					this.#lastRequest.ajax === undefined &&
					!this._status('shown')
				)
				{
					this.#render(this.#lastRequest.response);
				}
				return;
			}
			if (this.#lastRequest.ajax !== undefined)
			{
				this.#lastRequest.ajax.abort();
			}
			if (query.length < this.#options.minLength || !this.#searchInput.is(':visible'))
			{
				this._openMenu(false);
				return;
			}
			if (!this._status('shown') && this.#statusInitial)
			{
				this.#render([], true);
			}
			this.#lastRequest.queryString = query;
			this.#lastRequest.queryData = JSON.stringify(data);
			this.#lastRequest.response = undefined;
			this.#lastRequest.ajax = $.ajax({
				url: this.#options.ajax.url,
				method: 'POST',
				data,
				dataType: 'json',
				success: (json) => {
					this.#lastRequest.response = json.results;
					this.#render(this.#lastRequest.response);
				},
				complete: () => {
					this.#lastRequest.ajax = undefined;
				},
				error: () => {
					this.#lastRequest.queryData = undefined;
					this.#lastRequest.response = undefined;
				},
			});
		}

		#render(items, initialLoading = false)
		{
			const els = [];
			let firstActive;
			items = Object.entries(items);
			if (items.length > 0)
			{
				if (this.#options.numberOfItems > 0)
				{
					items = items.slice(0, this.#options.numberOfItems);
				}
				else
				{
					this.$menu().addClass('m-dropdownMenu--scroll');
				}
				for (const [key, item] of items)
				{
					const el = this.#menuItem.clone();
					const value = this.renderItem(el.children('a'), item, key);
					el.attr('data-value', typeof value === 'string' ? value : JSON.stringify(value)).data('value', value);
					if (!firstActive && !el.hasClass('m-dropdownMenu__row--disabled'))
					{
						firstActive = el;
					}
					els.push(el);
				}
			}
			else
			{
				const el = this.#menuItem.clone();
				if (initialLoading)
				{
					el.addClass('m-dropdownMenu__row--disabled m-dropdownMenu__row--loading');
				}
				else
				{
					el.addClass('m-dropdownMenu__row--disabled').children('a').text(this.getText('noResults'));
				}
				els.push(el);
			}
			this.$menu().empty().append(els);
			this._openMenu(true, firstActive);
		}

		getText(key)
		{
			if (this.#options.texts[key] === undefined)
			{
				throw new Error(key);
			}
			return this.#options.texts[key];
		}

		highlighter(text)
		{
			return Sim.BootstrapTypeaheadHighlighterFix.highlighter(text, this.#lastRequest.queryString);
		}

		#itemToHtml(item)
		{
			let html;
			if (typeof item === 'string')
			{
				html = this.highlighter(item);
			}
			else if (typeof item === 'object' && 'tag' in item)
			{
				const innerHtml = (item.content ?? '') === '' ? '' : this.#itemToHtml(item.content);
				html = $(document.createElement(item.tag)).html(innerHtml);
				for (const [attr, val] of Object.entries(item))
				{
					if (attr === 'tag' || attr === 'content') continue;
					html.attr(attr, val);
				}
			}
			else if (_.isArray(item))
			{
				html = $('<span>');
				for (const val of item)
				{
					html.append(this.#itemToHtml(val));
				}
			}
			else
			{
				throw new Error(JSON.stringify(item));
			}
			return html;
		}

		renderItem(el, item, key)
		{
			let last;
			for (const value of (_.isArray(item) ? item : [item]))
			{
				last = this.#menuItemText.clone();
				last.children('.js-text').html(this.#itemToHtml(value));
				el.append(last);
				el.append(' ');
			}
			if (last)
			{
				last.children('.a-interpunct').remove();
			}
			return key;
		}

		selectItem(el, value, event)
		{
			this.#lastValue = value ? JSON.stringify(value) : null;
			super.selectItem(el, value, event);
		}

	}

	return DropdownMenuSearch;
});
