define(['Helpers/ElementFinder', 'Nette', './BankTransactionMarkDuplicate'], (ElementFinder, Nette, BankTransactionMarkDuplicate) => {

	class BankTransactionMarkLive
	{

		#duplicate;
		#options;
		#root = new ElementFinder('.o-bankTransactionMark');
		#markedChangedElement;

		#remainderAmountElement = this.#root.findOne('.js-transactionRemainderAmount');
		#ignoredAmountElement = this.#root.findOne('.js-transactionIgnoredAmount');
		#markedAmountNewElement = this.#root.findOne('.js-transactionMarkedAmountNew');
		#markedElements = this.#createMarkedCurrencyMap();

		#paymentTypeElement = this.#root.findOne('input[type="hidden"][name="search[paymentType]"]');
		#amountSelector = 'input[name="actual[amount]"]';
		#amountDirectionSelector = 'select[name="actual[direction]"]';
		#receiverSelector = 'select[name="actual[receiver]"], select[name="actual[directions][transfer][receiver]"], select[name="actual[directions][deposit][receiver]"]';

		#ignoreCheckbox = this.#root.findOne('input[name="ignore[ignore]"]', false); // may not have permission to exist
		#ignoreEditCheckbox = this.#root.findOne('input[name="ignore[ignoreEdit]"]', this.#ignoreCheckbox.length !== 0);
		#ignoreEditAmountInput = this.#root.findOne('input[name="ignore[ignoreEditAmount]"]', this.#ignoreCheckbox.length !== 0);
		#ignoreEditAmountReadonly = this.#ignoreEditAmountInput.parent().children('input[readonly]');

		constructor(options)
		{
			this.#duplicate = new BankTransactionMarkDuplicate(
				options,
				() => this.#getAmountAndCurrency(),
				this.#root
			);
			this.#options = options;
			const update = _.debounce(() => { this.#update(); }, 100);
			this.#root.on(this.#amountSelector, 'change input', update);
			this.#root.on(this.#amountDirectionSelector, 'change', update);
			this.#ignoreEditAmountInput.on('change input', update);
			this.#ignoreCheckbox.on('change', update);
			this.#ignoreEditCheckbox.on('change', update);
			this.#root.deriveClosest('form').on(null, 'Rentflow:AjaxSubmitFillingForm.update', update);
			this.#update();

			this.#initUpdateReceiver();
		}

		#getAmountAndCurrency()
		{
			const $amount = this.#root.findOne(this.#amountSelector, false);
			let amount = NaN;
			if ($amount.length !== 0)
			{
				amount = parseFloat(parseFloat(Nette.getValue($amount[0])).toFixed(2));
			}
			if (!this.#isNumber(amount))
			{
				amount = null;
			}
			else if (this.#root.findOne(this.#amountDirectionSelector, false).val() === '-1')
			{
				amount *= -1;
			}
			if (amount !== null && this.#paymentTypeElement.val() === this.#options.TYPE_OWNER_TRANSFER)
			{
				// Sim\Intranet\BankTransactionMarkSearch::getRelativeAmount
				amount *= -1;
			}
			const currencyCode = $amount.attr('data-currency');
			return {amount, currencyCode};
		}

		#update()
		{
			const {amount, currencyCode} = this.#getAmountAndCurrency();

			let remainderAmount = parseFloat(this.#remainderAmountElement.attr('data-amount'));
			if (this.#remainderAmountElement.attr('data-currency') === currencyCode && amount !== null)
			{
				remainderAmount -= amount;
			}

			const ignoreAmount = this.#updateIgnoreAmount(remainderAmount);
			this.#ignoredAmountElement.text(this.#round24(parseFloat(this.#ignoredAmountElement.attr('data-amount')) + ignoreAmount));
			this.#remainderAmountElement.text(this.#round24(remainderAmount - ignoreAmount));
			this.#root.findOne('.js-transactionRemainderAmountNegativeWarning', amount !== null).toggleClass('m-controlGroup__alert--hidden', (remainderAmount - ignoreAmount) >= 0);
			this.#updateMarkedAmount(amount, currencyCode);

			this.#duplicate.check({amount, currencyCode});
		}

		#updateMarkedAmount(amount, currencyCode)
		{
			let markedForCurrency;
			if (amount !== null)
			{
				markedForCurrency = this.#markedElements.get(currencyCode) || this.#markedElements.get(null);
				markedForCurrency.currency.text(currencyCode);
				markedForCurrency.amount.text(this.#round2(parseFloat(markedForCurrency.amount.attr('data-amount')) + amount));
				markedForCurrency.count.text(parseInt(markedForCurrency.count.attr('data-count'), 10) + 1);
				markedForCurrency.countContainer.show();
				this.#markedAmountNewElement.toggle(markedForCurrency.isNew);
			}
			if (this.#markedChangedElement && (!markedForCurrency || markedForCurrency !== this.#markedChangedElement))
			{
				this.#markedChangedElement.amount.text(this.#round2(parseFloat(this.#markedChangedElement.amount.attr('data-amount'))));
				this.#markedChangedElement.count.text(this.#markedChangedElement.count.attr('data-count'), 10);
			}
			this.#markedChangedElement = markedForCurrency;
		}

		#createMarkedCurrencyMap()
		{
			const map = new Map;
			for (const $el of this.#root.iterateSome('.js-transactionMarkedAmount'))
			{
				map.set($el.attr('data-currency'), {
					isNew: false,
					amount: $el,
					count: null,
					countContainer: null,
					currency: $('<span>'),
				});
			}
			for (const $el of this.#root.iterateSome('.js-transactionMarkedCount'))
			{
				map.get($el.attr('data-currency')).count = $el;
				map.get($el.attr('data-currency')).countContainer = $el.closest('.js-transactionMarkedCountContainer');
			}
			map.set(null, {
				isNew: true,
				amount: this.#root.findOne('.js-transactionMarkedAmountNewAmount'),
				count: $('<span data-count="0">'),
				countContainer: $('<span>'),
				currency: this.#root.findOne('.js-transactionMarkedAmountNewCurrency'),
			});
			return map;
		}

		#updateIgnoreAmount(remainderAmount)
		{
			let ignoreAmount = 0;
			if (this.#ignoreCheckbox.prop('checked'))
			{
				if (this.#ignoreEditCheckbox.prop('checked'))
				{
					ignoreAmount = parseFloat(Nette.getValue(this.#ignoreEditAmountInput[0]));
					if (!this.#isNumber(ignoreAmount))
					{
						ignoreAmount = 0;
					}
				}
				else
				{
					ignoreAmount = remainderAmount;
					const format = this.#round24(ignoreAmount);
					this.#ignoreEditAmountInput.val(format);
					this.#ignoreEditAmountReadonly.val(format);
				}
			}
			return ignoreAmount;
		}

		#isNumber(value)
		{
			return typeof value === 'number' && !Number.isNaN(value);
		}

		#round2(number)
		{
			return Sim.number.format(number, 2);
		}

		#round24(number)
		{
			return Sim.number.format(number, [2, 4]);
		}

		#initUpdateReceiver()
		{
			const updateReceiver = (requireSome = false) => {
				for (const $el of this.#root.iterateSome(this.#receiverSelector, requireSome))
				{
					const $warning = (new ElementFinder($el.closest('.m-controlGroup'))).findOne('.js-transactionReceiverDifferentWarning');
					const val = $el.val();
					$warning.toggleClass('m-controlGroup__alert--hidden', val === '' || val === $warning.attr('data-receiver'));
				}
			};
			this.#root.on(this.#receiverSelector, 'change', () => updateReceiver(true));
			this.#root.on('select[name="actual[subtype]"]', 'change', () => updateReceiver());
			this.#root.deriveClosest('form').on(null, 'Rentflow:AjaxSubmitFillingForm.update', () => updateReceiver());
			updateReceiver();
		}

	}

	return BankTransactionMarkLive;
});
